import axios from 'axios';
import { authHeader } from './auth-header';
import { API_URL_BAN, ERR_MSG_CONEXION } from './constants';

class BanService {

  getRubros(AppCtx, tipo)
  {    
    return axios.post(API_URL_BAN + '/getrubros',
    {
      AppCtx: `${AppCtx.codigo}`,
      Tipo: `${tipo}`
    }, { headers: authHeader() }).then(
      response => {
        return response.data;
      }
    )
    .catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  registrarRubro(AppCtx, Rubro)
  {    
    var data = JSON.stringify(Rubro);
    return axios.post(API_URL_BAN + '/registrarrubro',
    {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${data}`
    }, { headers: authHeader() }).then(
      response => {
        return response.data;
      }
    )
    .catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  rubroEnUso(AppCtx, Rubro)
  {    
    return axios.post(API_URL_BAN + '/rubroenuso',
    {
      AppCtx: `${AppCtx.codigo}`,
      Id: `${Rubro.id_rubro}`
    }, { headers: authHeader() }).then(
      response => {
        return response.data;
      }
    )
    .catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  removerRubro(AppCtx, Rubro)
  {    
    return axios.post(API_URL_BAN + '/removerrubro',
    {
      AppCtx: `${AppCtx.codigo}`,
      Id: `${Rubro.id_rubro}`
    }, { headers: authHeader() }).then(
      response => {
        return response.data;
      }
    )
    .catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getCuentas(AppCtx)
  {    
    return axios.post(API_URL_BAN + '/getcuentas',
    {
      AppCtx: `${AppCtx.codigo}`
    }, { headers: authHeader() }).then(
      response => {
        return response.data;
      }
    )
    .catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }  

  GetCuentasXLogin(AppCtx)
  {    
    return axios.post(API_URL_BAN + '/getcuentasxlogin',
    {
      AppCtx: `${AppCtx.codigo}`
    }, { headers: authHeader() }).then(
      response => {
        return response.data;
      }
    )
    .catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }  

  getCuentasTipos(AppCtx) {
    return axios.post(API_URL_BAN + '/getcuentatipos',
      {
        AppCtx: `${AppCtx.codigo}`
      }, { headers: authHeader() }).then(
        response => {
          return response.data;
        }
      )
      .catch(
        err => {
          if(err.response){
            return Promise.reject(err.response.data);          
          } else {
            return Promise.reject(ERR_MSG_CONEXION);
          }        
        }
      );  
  }  

  registrarCuenta(AppCtx, Cuenta)
  {    
    var data = JSON.stringify(Cuenta);
    return axios.post(API_URL_BAN + '/registrarcuenta',
    {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${data}`
    }, { headers: authHeader() }).then(
      response => {
        return response.data;
      }
    )
    .catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  cuentaEnUso(AppCtx, Cuenta)
  {    
    return axios.post(API_URL_BAN + '/cuentaenuso',
    {
      AppCtx: `${AppCtx.codigo}`,
      Id: `${Cuenta.id_cuenta}`
    }, { headers: authHeader() }).then(
      response => {
        return response.data;
      }
    )
    .catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  removerCuenta(AppCtx, Cuenta)
  {    
    return axios.post(API_URL_BAN + '/removercuenta',
    {
      AppCtx: `${AppCtx.codigo}`,
      Id: `${Cuenta.id_cuenta}`
    }, { headers: authHeader() }).then(
      response => {
        return response.data;
      }
    )
    .catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getHistorialTrxs(AppCtx, Data)
  {
    return axios.post(API_URL_BAN + '/gettrxs', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${JSON.stringify(Data)}`
    }, { headers: authHeader() }).then(response => {
    return response.data;
    }
    ).catch(
    err => {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  RegistrarTrx(AppCtx, Trx)
  {    
    var data = JSON.stringify(Trx);
    return axios.post(API_URL_BAN + '/registrartrx',
    {
      AppCtx: `${AppCtx.codigo}`,
      Data: `${data}`
    }, { headers: authHeader() }).then(
      response => {
        return response.data;
      }
    )
    .catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }
}

export default  new BanService();
